<template>

  <div id="page-user-list">

    <div class="vx-card p-6" v-show="loaded">

      <!-- Header -->
      <div class="flex justify-between flex-wrap items-center">

        <!-- Search Form -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery"
                  @input="updateSearchQuery" placeholder="Search..."/>

        <div style="display: grid; grid-template-columns: repeat(2, auto); grid-gap: 16px; align-items: end;">
          <div>
            <small class="mb-1">{{ $i18n.t('Filter by Customer') }}</small>
            <v-select v-model="company" :clearable="false" label="nama" :options="companyOptions"
                      style="width: 250px;"></v-select>
          </div>
          <!-- Button Add -->
          <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="addItem">Add Item
          </vs-button>
        </div>

      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <!-- Pagination -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }} - {{
                  itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length
                }} of {{ itemsData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <!-- PAGINATION -->
          <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage"/>
        </div>
      </div>

    </div>

    <!--  Popup  -->
    <vs-popup classContent="popup-example" :title="dynamicTitle" :active.sync="popup">

      <div class="vx-row py-3 px-4 w-full" v-show="mode === 'detail'">
          <div class="vx-col md:w-6/12 mb-4 break-words">
              <h1 class="text-lg font-semibold mb-2">{{ $i18n.t('CustomerName') }}</h1>
              <span class="block">{{ customerDetail.nama_karyawan }}</span>
          </div>

          <div class="vx-col md:w-5/12 mb-4 justify-self-end break-words">
            <h1 class="text-lg font-semibold mb-2">{{ $i18n.t('Division') }}n</h1>
            <span class="block">{{ customerDetail.divisi }}</span>
          </div>

          <div class="vx-col md:w-6/12 mb-4 break-words">
            <h1 class="text-lg font-semibold mb-2">{{ $i18n.t('Company') }}</h1>
            <span class="block">{{ customerDetail.company }}</span>
          </div>

          <div class="vx-col md:w-5/12 mb-4 justify-self-end break-words">
            <h1 class="text-lg font-semibold mb-2">{{ $i18n.t('NIK') }}n</h1>
            <span class="block">{{ customerDetail.nik ? customerDetail.nik : '-' }}</span>
          </div>

          <div class="vx-col md:w-6/12 mb-4 break-words">
            <h1 class="text-lg font-semibold mb-2">{{ $i18n.t('NoKTP') }}</h1>
            <span class="block">{{ customerDetail.no_ktp ? customerDetail.no_ktp : '-' }}</span>
          </div>

          <div class="vx-col md:w-5/12 mb-4 justify-self-end break-words">
            <h1 class="text-lg font-semibold mb-2">{{ $i18n.t('NPWP') }}n</h1>
            <span class="block">{{ customerDetail.no_npwp ? customerDetail.no_npwp : '-' }}</span>
          </div>

          <div class="vx-col md:w-6/12 mb-4 break-words">
            <h1 class="text-lg font-semibold mb-2">{{ $i18n.t('Gender') }}</h1>
            <span class="block">{{ customerDetail.jenis_kelamin }}</span>
          </div>

          <div class="vx-col md:w-5/12 mb-4 justify-self-end break-words">
            <h1 class="text-lg font-semibold mb-2">{{ $i18n.t('Address') }}n</h1>
            <span class="block">{{ customerDetail.alamat }}</span>
          </div>

          <div class="vx-col md:w-6/12 break-words">
            <h1 class="text-lg font-semibold mb-2">{{ $i18n.t('Email') }}</h1>
            <span class="block">{{ customerDetail.email }}</span>
          </div>
      </div>

      <div v-show="mode !== 'detail'">
        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="form.nama_karyawan"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('Company') }}</small>
            <v-select v-model="form.company" :clearable="false" label="nama" :options="companyOptions"></v-select>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Division')" v-model="form.divisi"/>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Position')" v-model="form.posisi"/>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Email')" v-model="form.email"/>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('PhoneNumber')" v-model="form.telp"/>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('Gender') }}</small>
            <ul class="centerx mt-1 mb-3" style="display: flex; ">
              <li class="mr-3">
                <vs-radio v-model="form.jenis_kelamin" vs-value="Laki-Laki">{{ $i18n.t('Male') }}</vs-radio>
              </li>
              <li>
                <vs-radio v-model="form.jenis_kelamin" vs-value="Perempuan">{{ $i18n.t('Female') }}</vs-radio>
              </li>
            </ul>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('PlaceOfBirth')" v-model="form.tempat_lahir"/>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <label class="vs-input--label">{{ $i18n.t('DateOfBirth') }}</label>
            <datepicker format="d/MM/yyyy" :value="form.tanggal_lahir"
                        @input="form.tanggal_lahir = $moment($event)"></datepicker>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <label for="alamat" class="vs-input--label">{{ $i18n.t('Address') }}</label>
            <vs-textarea rows="6" id="alamat" class="w-full mb-3" v-model="form.alamat"/>
          </div>
        </div>

        <div class="mt-5">
          <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
            {{ $i18n.t('Save') }}
          </vs-button>
        </div>
      </div>

    </vs-popup>

  </div>

</template>

<script>
import {AgGridVue} from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import Datepicker from "vuejs-datepicker";
// import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import axios from '@/axios';

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'


export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,
    flatPickr,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile,
  },
  data() {
    return {
      loaded: false,
      searchQuery: '',
      company: '',
      companyOptions: [],

      form: {
        currency: 'IDR',
      },
      customerDetail: {},
      popup: false,
      mode: 'add',
      itemSelected: false,
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        time_24hr: true,
        defaultHour: 8,
        minuteIncrement: 30
      },

      // AgGrid
      gridApi: null,
      gridOptions: {},
      itemsData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Company'),
          field: 'company',
          filter: true,
        },
        {
          headerName: this.$i18n.t('Name'),
          field: 'nama_karyawan',
          filter: true,
          cellRendererFramework: 'CellRendererLink',
          cellRendererParams: {
            showDetail: this.showDetail.bind(this)
          }
        },
        {
          headerName: this.$i18n.t('Division'),
          field: 'divisi',
          filter: true,
        },
        {
          headerName: this.$i18n.t('Email'),
          field: 'email',
          filter: true,
        },
        {
          headerName: this.$i18n.t('Phone'),
          field: 'telp',
          filter: true,
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRendererParams: {
            editItem: this.editItem.bind(this),
            deleteItem: this.deleteItem.bind(this),
          },
        }
      ],
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    tempat_tanggal_lahir() {
      return this.form.tempat_lahir + ', ' + this.form.tanggal_lahir;
    },
    dynamicTitle(){
      if(this.mode !== 'detail'){
        return `${this.mode} item`
      }
      return 'Detail Customer'
    }
  },
  methods: {
    loading() {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    getData() {
      let params = {
        company: this.company.nama
      };

      axios.get('/sales/customer-contacts', {params}).then(resp => this.itemsData = resp.data).catch(err => {
        this.$vs.notify({
          title: 'Opps something error',
          text: err.data.message,
          color: 'danger'
        })
      })
        .finally(() => {
          this.onLoaded();
        })
    },
    showDetail(item){
        this.mode = 'detail';
        this.popup = true;
        this.customerDetail = item;
        console.log(item)
    },
    addItem() {
      this.popup = true;
      this.mode = 'add';
    },
      editItem(item) {
        this.popup = true;
        this.mode = 'edit';
        this.itemSelected = item;

        this.form = {
          nama_karyawan: item.nama_karyawan,
          company: this.companyOptions.find(item2 => item2.nama == item.company),
          divisi: item.divisi,
          posisi: item.posisi,
          email: item.email,
          telp: item.telp,
          jenis_kelamin: item.jenis_kelamin,
          tempat_lahir: item.tempat_tanggal_lahir.split(', ')[0],
          tanggal_lahir: item.tempat_tanggal_lahir.split(', ')[1],
          alamat: item.alamat,
        };

      },
      deleteItem(item) {
        axios.delete('/sales/customer-contacts/' + item.id).then(resp => {
          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
          });
        }).catch(err => {
          this.$vs.notify({
            title: 'Opps something error',
            text: err.data.message,
            color: 'danger'
          })
        })
      },
    storeItem() {
      if (this.mode == 'add') {
        let body = {
          nama_karyawan: this.form.nama_karyawan,
          company: this.form.company.nama,
          divisi: this.form.divisi,
          posisi: this.form.posisi,
          email: this.form.email,
          telp: this.form.telp,
          jenis_kelamin: this.form.jenis_kelamin,
          tempat_tanggal_lahir: this.tempat_tanggal_lahir,
          alamat: this.form.alamat,
        };

        axios.post('/sales/customer-contacts/', body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.mode = false;
          });
        }).catch(err => {
          this.$vs.notify({
            title: 'Opps something error',
            text: err.data.message,
            color: 'danger'
          })
        })
      } else if (this.mode == 'edit') {
        let body = {
          nama_karyawan: this.form.nama_karyawan,
          company: this.form.company.nama,
          divisi: this.form.divisi,
          posisi: this.form.posisi,
          email: this.form.email,
          telp: this.form.telp,
          jenis_kelamin: this.form.jenis_kelamin,
          tempat_tanggal_lahir: this.tempat_tanggal_lahir,
          alamat: this.form.alamat,
          _method: 'PUT',
        };

        axios.post('/sales/customer-contacts/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.mode = false;
          });
        }).catch(err => {
          this.$vs.notify({
            title: 'Opps something error',
            text: err.data.message,
            color: 'danger'
          })
        })
      }
    },

    onLoaded() {
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  watch: {
    company() {
      this.getData();
    },
    popup() {
      if (!this.popup) {
        this.form = {
          currency: 'IDR',
        };
      }
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`
    }

    this.gridApi.sizeColumnsToFit()
  },
  created() {

    this.loading();

    axios.get('/sales/company', {}).then(resp => this.companyOptions = resp.data).catch(err => {
      this.$vs.notify({
        title: 'Opps something error',
        text: err.data.message,
        color: 'danger'
      })

    })


    this.getData();

  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }

  //.vs-popup{
  //  overflow-y: scroll !important;
  //  overflow-x: hidden !important;
  //}
}
</style>
