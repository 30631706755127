<template>
  <div class="flex items-center">
    <span @click="openPopup" class="text-primary cursor-pointer">{{ params.value }}</span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',

  methods:{
    openPopup(){
      this.params.showDetail(this.params.data)
    }
  }
}
</script>
